<template lang="pug">
InputGroup(name="expires_at", :label="label", :description="description")
  DatePicker(:value="value", :placeholder="placeholder", :presets="expiresAtPresets", @input="updateValue", show-time)
</template>

<script>
import { add as dateAdd } from "date-fns"

export default {
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: "This will not expire. Select a date to expire."
    },
    label: {
      type: String,
      default: "Expiration Date - optional"
    },
    description: {
      type: String,
      default: null
    }
  },
  data: () => ({
    expiresAtPresets: [
      { label: "1 month", date: dateAdd(new Date(), { months: 1 }) },
      { label: "6 months", date: dateAdd(new Date(), { months: 6 }) },
      { label: "1 year", date: dateAdd(new Date(), { years: 1 }) },
      { label: "3 years", date: dateAdd(new Date(), { years: 3 }) },
      { label: "5 years", date: dateAdd(new Date(), { years: 5 }) }
    ]
  }),
  methods: {
    updateValue: function (value) {
      this.$emit("input", value)
    }
  }
}
</script>
