<template>
  <InputGroup label="Full size - Optional" name="image_display">
    <div class="mt-2 inline-flex items-center">
      <ToggleSwitch :value="value" true-value="full" false-value="cover" @change="updateValue" class="rounded-full" />
      <span class="ml-4"
        >By default your image will be optimised for photos. Toggle this option if you'd prefer a full size image
        (better for text-inclusive images).</span
      >
    </div>
  </InputGroup>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    }
  },
  methods: {
    updateValue: function (value) {
      // @note: This check is in place because there's something wrong with ToggleSwitch causing a double emit and emitting both the value and event
      if (typeof value !== "object") {
        this.$emit("input", value)
      }
    }
  }
}
</script>
