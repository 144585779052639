<template lang="pug">
InputGroup(name="published_at", :label="label", :description="description")
  DatePicker(:value="value", :placeholder="placeholder", :presets="publishedAtPresets", @input="updateValue", show-time)
</template>

<script>
import { sub as dateSub } from "date-fns"

export default {
  props: {
    value: {
      type: String
    },
    description: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: "Publish Date - optional"
    },
    placeholder: {
      type: String,
      default: "This will be hidden. Select a date to display."
    }
  },
  data: () => ({
    publishedAtPresets: [
      { label: "Yesterday", date: dateSub(new Date(), { days: 1 }) },
      { label: "Today", date: new Date() }
    ]
  }),
  methods: {
    updateValue: function (value) {
      this.$emit("input", value)
    }
  }
}
</script>
