<template>
  <FormModal
    title="Add Itinerary"
    endpoint="blocks"
    :data="data"
    v-bind="$attrs"
    @success="onSuccess"
    class="add-itinerary-block-modal"
  >
    <!-- <Tabs>
      <Tab title="Content"> -->
    <h2 class="section-title">Details</h2>

    <InputGroup name="itinerary_type_id" label="Type - optional">
      <Select v-model="data.itinerary_type_id" endpoint="options/itinerary-types" prepend-option="None" />
    </InputGroup>

    <InputGroup name="title" label="Title - optional">
      <TextInput v-model="data.title" textarea :rows="1" />
    </InputGroup>

    <InputGroup name="subtitle" label="Subtitle - optional">
      <TextInput v-model="data.subtitle" />
    </InputGroup>

    <MarkdownInput v-model="data.synopsis" name="synopsis" label="Description - optional" />

    <InputGroup name="featured_image" label="Image - optional" description="JPG, PNG, GIF or WEBP. Up to 10 MB.">
      <ImageInput v-model="data.featured_image" />
    </InputGroup>

    <ImageDisplayInput v-model="data.image_display" />

    <h2 class="section-title">Date(s) &amp; Duration</h2>

    <InputGroup name="dates" label="Date(s) - optional">
      <TextInput v-model="data.dates" />
    </InputGroup>

    <div class="grid md:grid-cols-2 md:gap-8">
      <InputGroup name="duration" label="Duration - optional" class="mb-8 md:mb-0">
        <TextInput v-model="data.duration" />
      </InputGroup>

      <InputGroup name="duration_unit" label="Duration unit - optional" class="mb-8 md:mb-0">
        <Select v-model="data.duration_unit" endpoint="options/itinerary-duration-units" />
      </InputGroup>
    </div>

    <h2 class="section-title">Pricing</h2>

    <div class="grid md:grid-cols-2 md:gap-8">
      <InputGroup name="price" label="Price - optional">
        <TextInput v-model="data.price" />
      </InputGroup>

      <InputGroup name="sale_price" label="Sale Price - optional">
        <TextInput v-model="data.sale_price" />
      </InputGroup>
    </div>

    <!-- <InputGroup name="currency_id" label="Currency - optional">
      <Select v-model="data.currency_id" endpoint="options/currencies" />
    </InputGroup> -->

    <InputGroup label="Price Options - optional">
      <div class="mt-4 md:flex md:flex-wrap">
        <Checkbox v-model="data.price_per_person" label="Price per person" class="md:mr-6" />
        <Checkbox v-model="data.price_starting_from" label="Price starting from" class="md:mr-6" />
      </div>
    </InputGroup>

    <MarkdownInput v-model="data.price_fine_print" name="price_fine_print" label="Fine Print - optional" :rows="2" />

    <h2 class="section-title">Primary Call to Action</h2>

    <InputGroup
      name="primary_cta_label"
      label="Primary Call to Action Label - optional"
      description="Examples: Get a quote, Contact me for details"
    >
      <TextInput v-model="data.primary_cta_label" />
    </InputGroup>

    <InputGroup name="primary_cta_link" label="Primary Call to Action Link - optional">
      <TextInput v-model="data.primary_cta_link" />
    </InputGroup>

    <InputGroup name="primary_cta_link_use_advisor_email_on_inspires" label="Inspires & Copilot - optional">
      <div class="mt-4">
        <Checkbox
          v-model="data.primary_cta_link_use_advisor_email_on_inspires"
          label="Make call to action link to sender's email"
        />
      </div>
    </InputGroup>

    <h2 class="section-title">Secondary Call to Action</h2>

    <InputGroup name="secondary_cta_type" label="Type">
      <Select v-model="data.secondary_cta_type" endpoint="options/block-secondary-cta-types" />
    </InputGroup>

    <InputGroup
      name="secondary_cta_label"
      label="Secondary Call to Action Label - Optional"
      description="Examples: View Itinerary Map, Scan QR Code"
    >
      <TextInput v-model="data.secondary_cta_label" />
    </InputGroup>

    <InputGroup
      v-show="data.secondary_cta_type === 'link'"
      name="secondary_cta_link"
      label="Secondary Call to Action Link - optional"
      description="Example: https://... or mailto://..."
    >
      <TextInput v-model="data.secondary_cta_link" />
    </InputGroup>

    <InputGroup
      v-show="data.secondary_cta_type === 'image'"
      name="itinerary_image"
      label="Secondary Call to Action Image - Optional"
    >
      <ImageInput v-model="data.itinerary_image" />
    </InputGroup>

    <h2 class="section-title">Visibility</h2>
    <PublishAtInput v-model="data.published_at" />
    <ExpiresAtInput v-model="data.expires_at" />

    <h2 v-if="!collection_id" class="section-title">Collection(s)</h2>

    <InputGroup
      v-if="!collection_id"
      name="collection_ids"
      label="Collection(s) - optional"
      description="Click the chevron or your down arrow key to see list"
    >
      <MultiSelect
        v-model="data.collection_ids"
        placeholder="Add this to a collection..."
        endpoint="options/all/collections"
        option-label="label"
      />
    </InputGroup>

    <!-- </Tab>

      <Tab title="Design">...</Tab>
    </Tabs> -->
  </FormModal>
</template>

<script>
// import { Tabs, Tab } from "@syntax51/app-kit"
import ExpiresAtInput from "@/components/Form/ExpiresAtInput.vue"
import ImageDisplayInput from "../../../components/Form/ImageDisplayInput.vue"
import MarkdownInput from "@/components/Form/MarkdownInput.vue"
import PublishAtInput from "@/components/Form/PublishAtInput.vue"

export default {
  components: {
    ExpiresAtInput,
    ImageDisplayInput,
    MarkdownInput,
    PublishAtInput
    // Tab,
    // Tabs
  },
  props: {
    collection_id: {
      type: Number
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      block_type: "itinerary",
      template: "simple",
      featured_image: null,
      image_display: "cover",
      itinerary_type_id: null,
      title: "",
      subtitle: "",
      synopsis: "",
      duration: "",
      duration_unit: "",
      dates: "",
      currency_id: null,
      price: null,
      price_per_person: false,
      price_starting_from: false,
      price_fine_print: "",
      sale_price: null,
      primary_cta_label: null,
      primary_cta_link: "",
      primary_cta_link_use_advisor_email_on_inspires: true,
      secondary_cta_type: "link",
      secondary_cta_label: null,
      secondary_cta_link: null,
      itinerary_image: null,
      published_at: null,
      expires_at: null
    }
  }),
  created() {
    this.data.collection_id = this.collection_id
    this.data.published_at = new Date().toUTCString()
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Itinerary successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.add-itinerary-block-modal {
  .tab {
    @apply pt-8 md:pt-12;
  }

  .section-title {
    @apply mb-6 border-b-2 pb-3 text-base;
    @apply md:mb-8;
  }

  * + .section-title {
    @apply mt-6;
    @apply md:mt-8;
    @apply lg:mt-12;
  }
}
</style>
